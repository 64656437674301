<template>
  <div class="box">
    <div @click="isCollapse = !isCollapse" v-show="!isCollapse" class="fixed-button">
      <h3 class="m-0 p-0">
        <i class="fas fa-angle-double-right"></i>
      </h3>
    </div>
    <div class="left-menu" v-show="isCollapse">
      <div @click="isCollapse = !isCollapse" class="show-left">
        <h3 class="text-right m-0 p-0">
          <span v-show="isCollapse">
            <i class="fas fa-angle-double-left"></i>
          </span>
          <span v-show="!isCollapse" style="width: 50px;">
            <i class="fas fa-angle-double-right"></i>
          </span>
        </h3>
      </div>
      <div v-show="isCollapse" class="collapse-box">
        <el-menu :default-active="activeRoute" @select="selectPage">
          <!-- <el-menu-item index="SatManagementAdaptive">
            <i class="el-icon-menu"></i>
            <b>{{ $t("sat.Adaptive Tests") }}</b>
          </el-menu-item> -->

          <el-menu-item index="SatManagementCompose">
            <i class="el-icon-menu"></i>
            <b>{{ $t("sat.Full Tests") }}</b>
          </el-menu-item>
          <el-menu-item index="SatManagementPractice">
            <i class="el-icon-menu"></i>
            <b>{{ $t("sat.Partial Tests") }}</b>
          </el-menu-item>
          <el-menu-item index="SatManagementDrill">
            <i class="el-icon-menu"></i>
            <b>{{ $t("sat.Drill Practice") }}</b>
          </el-menu-item>
          <el-menu-item index="AllQuestions">
            <i class="el-icon-s-order" />
            <b>{{ $t("sat.QuestionsBox") }}</b>
          </el-menu-item>
          <el-menu-item index="AdminSatCourses">
            <i class="el-icon-s-home"></i>
            <b>{{ $t("TeacherCourses.Classes Management") }}</b>
          </el-menu-item>
          <el-menu-item index="QuestionTags">
            <i class="el-icon-s-management" />
            <b>{{ $t("sat.Labels") }}</b>
          </el-menu-item>
          <el-submenu index="4">
            <template slot="title">
              <div class="menu-title">
                <i class="el-icon-s-data"></i>
                <b>{{ $t("sat.Test Results") }}</b>
              </div>
            </template>
            <el-menu-item index="ViewAllResults">
              <b>{{ $t("sat.Test Results") }}</b>
            </el-menu-item>
            <el-menu-item index="ResultsCombine">
              <b>{{ this.$t("sat.Combine Tests") }}</b>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="AdminUsers">
            <template slot="title">
              <div class="menu-title">
                <i class="el-icon-s-finance" />
                <b>{{ $t("sat.Users") }}</b>
              </div>
            </template>
            <el-menu-item index="AdminUsers">
              <b>{{ $t("sat.Users") }}</b>
            </el-menu-item>
            <el-menu-item index="AdminUsersLogs">
              <b>{{ $t("sat.User Activities") }}</b>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="AdminCompany">
            <i class="el-icon-s-management" />
            <b>Companies</b>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="right-content">
      <div class="right-content-detail">
        <el-card shadow="never"> <router-view /></el-card>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";

export default {
  metaInfo() {
    return {
      title: "SAT Practices - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common],

  props: [],
  data() {
    return {
      isCollapse: this.isPhone ? false : true
    };
  },
  computed: {
    activeRoute() {
      if (this.$route.name == "Admin") {
        return "CreateEmail";
      } else if (this.$route.name == "SatAddPractice") {
        if (this.$route.query.type && this.$route.query.type === "drill") {
          return "SatAddDrillPractice";
        } else {
          return this.$route.name;
        }
      } else if (this.$route.name == "SatManagement") {
        if (this.$route.query.type === "adaptive") {
          return "SatManagementAdaptive";
        } else if (this.$route.query.type === "practice") {
          return "SatManagementPractice";
        } else if (this.$route.query.type === "composePractices") {
          return "SatManagementCompose";
        } else if (this.$route.query.type === "drill") {
          return "SatManagementDrill";
        } else {
          return "SatManagementCompose";
        }
      } else {
        return this.$route.name;
      }
    },
    instant() {
      return instant;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme.name;
    }
  },
  watch: {},

  async mounted() {},

  methods: {
    openMenu(route) {
      console.log(route);
    },
    selectPage(route) {
      if (route === "SatAddDrillPractice") {
        this.$router.replace({
          name: "SatAddPractice",
          query: { type: "drill" }
        });
      } else if (route === "SatManagementPractice") {
        this.$router.replace({
          name: "SatManagement",
          query: { type: "practice" }
        });
      } else if (route === "SatManagementCompose") {
        this.$router.replace({
          name: "SatManagement",
          query: { type: "composePractices" }
        });
      } else if (route === "SatManagementAdaptive") {
        this.$router.replace({
          name: "SatManagement",
          query: { type: "adaptive" }
        });
      } else if (route === "SatManagementDrill") {
        this.$router.replace({
          name: "SatManagement",
          query: { type: "drill" }
        });
      } else {
        this.$router.replace({
          name: route
        });
      }
    }
  }
};
</script>

<style scoped>
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box-ivyway {
  padding-top: 100px;
}
.box .left-menu {
  background: #fff;
  max-width: 170px;
  height: 100%;
}
.collapse-box {
  min-width: 170px;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 0 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  /* min-width: 500px; */
  overflow: auto;
}
.show-left {
  color: #86909c;
  padding: 10px 20px;
  padding-bottom: 0;
  cursor: pointer;
  /* width: 50px; */

}
.fixed-button {
  position: fixed;
  left: 0;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 0 .5rem .5rem 0;
  cursor: pointer;
  z-index: 9999;
  color: #86909c;
}
</style>
